define('ember-cli-filter-by-query/index', ['exports', 'ember', 'ember-cli-filter-by-query/util/filter'], function (exports, Ember, filterByQuery) {

  'use strict';

  var computedFilterByQuery = function(dependentKey, propertyKeys, queryKey, options) {
    propertyKeys = Ember['default'].makeArray(propertyKeys);

    return Ember['default'].computed( queryKey, '' + dependentKey + '.@each.{' + propertyKeys.join(',') + '}', function() {

      var array = this.get(dependentKey);
      var query = this.get(queryKey) || '';

      return filterByQuery['default'](array, propertyKeys, query, options);

    });
  };

  exports['default'] = computedFilterByQuery;

});